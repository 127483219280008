import React from 'react';
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';

import Layout from '../components/layout';
import Scroll from '../components/scroll';
import Sponsors from '../components/sponsors';
import Timetable from '../components/timetable';
import Keynotes from '../components/keynotes';
import Team from '../components/team';
import logo from '../assets/images/barcamp_logo.svg';

import Img from 'gatsby-image';

const EditionPage = props => {
  let node = props.pageContext;

  return (
    <Layout fullMenu>
      <section id="banner">
        <div className="inner">
          <h2>
            <img className="banner-image" src={logo} alt="logo" />
          </h2>
          <h3>{node.subtitle}</h3>
          {(node.end_date === node.date) ? (
          <p>
            {' '}
            {dayjs(node.end_date)
              .locale(de)
              .format('DD. MMMM YYYY')}
          </p>
          ) : (
          <p>
            {' '}
            {dayjs(node.date)
              .locale(de)
              .format('DD. ')}{' '}
            -{' '}
            {dayjs(node.end_date)
              .locale(de)
              .format('DD. MMMM YYYY')}
          </p>
          )}
          <ul className="actions special">
            {node.hero_buttons &&
              node.hero_buttons.map(b => (
                <li key={b.button_text}>
                  <a href={b.button_url} className="button primary">
                    {b.button_text}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <Scroll type="id" element="four">
          <a href="#four" className="more">
            Schedule
          </a>
        </Scroll>
      </section>

      <section id="two" className="wrapper alt style2">
        {node.textblock &&
          node.textblock.map(block => (
            <section className="spotlight">
              <div className="image">
                {block.image.localFile.childImageSharp ? (
                  <Img
                    alt="Bild zu Block"
                    className="card-img-top"
                    fluid={block.image.localFile.childImageSharp.fluid}
                  />
                ) : (
                  <img
                    src={block.image.localFile.publicURL}
                    alt="Bild zu Block"
                    className="card-img-top"
                  />
                )}
              </div>
              <div className="content">
                <h2>{block.heading}</h2>
                <div dangerouslySetInnerHTML={{ __html: block.text }}></div>
              </div>
            </section>
          ))}
      </section>

      {node.keynotes.length > 0 && (
        <section id="three" className="wrapper style1 special">
          <div className="inner">
            <header className="major">
              <h2>Keynotes</h2>
            </header>
            <Keynotes items={node.keynotes} />
          </div>
        </section>
      )}

      <section id="four" className="wrapper style3 special">
        <div className="inner">
          <header className="major">
            <h2>Schedule</h2>
          </header>
          <Timetable items={node.zeitplan} />
        </div>
      </section>

      <section id="five" className="wrapper style5 special">
        <div className="inner">
          <header className="major">
            <h2>Sponsors</h2>
            <p>This event is free - thanks to our sponsors!</p>
          </header>
          <Sponsors items={node.sponsors} />
        </div>
      </section>

      <section id="six" className="wrapper style2 special">
        <div className="inner">
          <header className="major">
            <h2>Our Team</h2>
          </header>
          <Team items={node.team} />
        </div>
      </section>
    </Layout>
  );
};

export default EditionPage;
